/* globals Modernizr */

Modernizr.addTest('ms', function () {
    return !!window.MSStream;
});

Modernizr.addTest('ipad', function () {
    return !!navigator.userAgent.match(/iPad/i) && !Modernizr.ms;
});

Modernizr.addTest('iphone', function () {
    return !!navigator.userAgent.match(/iPhone/i) && !Modernizr.ms;
});

Modernizr.addTest('ipod', function () {
    return !!navigator.userAgent.match(/iPod/i) && !Modernizr.ms;
});

Modernizr.addTest('ios', function () {
    return (Modernizr.ipad || Modernizr.ipod || Modernizr.iphone);
});
